import "./Header.module.css";
import {useTranslation} from "next-i18next";
import { Select } from 'antd';
import logo from "../../../img/branding/logo_white.png";
import logoSmall from "../../../img/branding/logo_small.png";
import Button from "../Button/Button";
import avatarPlaceholder from "../../../img/home/home/avatar_placeholder.png";
import Link from "next/link";
import {languages} from "../../../constants";
import styles from "./Header.module.css";
import {motion} from "framer-motion";
import arrowImage from "../../../img/components/Select/arrow.png"
import moreIcon from "../../../img/components/shared/header/more.png"
import {useEffect, useRef, useState} from "react";
import {useWindowSize} from "../../../hooks/useWindowSize";
import UserService from "../../../services/User/user.service";
import {useAppSelector} from "../../../redux/store";

const { Option } = Select;

export default function Header() {
	const { t } = useTranslation();
	const { i18n } = useTranslation();
	const { width } = useWindowSize();
	const user = useAppSelector((state) => state.user.user);

	const [showResponsiveMenu, setShowResponsiveMenu] = useState(false);

	function useOutsideAlerter(ref:any) {
		useEffect(() => {
			function handleClickOutside(event:any) {
				if (ref.current && !ref.current.contains(event.target)) {
					setShowResponsiveMenu(false);
				}
			}
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}

	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef);

	return(
		<header
			className={styles.home_header}
			data-testid="cy_header"
		>
			<div className={"row"}>
				<Link href={"/"}
					  data-testid="cy_header_link_home"
				>
					<motion.img
						className={styles.home_header_logo}
						src={width && width < 800 ? logoSmall.src : logo.src}
						alt="shary_logo_header"
					/>
				</Link>
				<Link href="/#how" className={styles.home_header_links}>
					<p className={styles.home_header_link}>
						{t("home.header.how_works")}
					</p>
				</Link>
				<Link
					href="/category"
					className={styles.home_header_links}
					id="header_category_link_full_screen"
				>
					<p className={styles.home_header_link}>
						{t("home.header.categories")}
					</p>
				</Link>
				<Link
					id="header_search_article_link_full_screen"
					href="/posts"
					className={styles.home_header_links}
				>
					<p className={styles.home_header_link}>
						{t("home.header.search")}
					</p>
				</Link>
				<Link
					href="/info/companies"
					className={styles.home_header_links}
					id="header_for_companies_link_full_screen"
				>
					<p className={styles.home_header_link}>
						{t("home.header.companies")}
					</p>
				</Link>
			</div>
			<div className="row">

				<Select
					suffixIcon={
						<motion.img src={arrowImage.src} alt="arrow" style={{height: "5px"}} />
					}
					value={i18n.language || "de"}
					onChange={(lang:string) => {
						i18n.changeLanguage(lang)
						new UserService().changeLanguage({language: lang || "de"});
					}}
				>
					{languages.map((lang:string) => (
						<Option
							key={lang}
							value={lang}
							id={lang}
						>
							{t(`languages.fullName.${lang}`)}
						</Option>
					))}
				</Select>

				{user?._id
					? <Link href={"/dashboard"}>
						<div className={styles.header_authenticated}>
							{user?.imageUrl?.length > 0
								? <motion.img
									src={user?.imageUrl}
									alt="shary_avatar_header"
								/>
								: <motion.img
									src={avatarPlaceholder.src}
									alt="user_image"
								/>
							}
							<p>{t("home.header.dashboard")}</p>
						</div>
					</Link>
					: <>
						<Link href={"/auth/signin"}>
							<p className={`${styles.home_header_link} ${styles.login}`}>
								{t("home.header.login")}
							</p>
						</Link>
						<Link href={"/auth/signup"}>
							<p className={styles.home_header_link}>
								{t("home.header.register")}
							</p>
						</Link>
					</>
				}

				<div style={{width: "40px"}} />

				{width && width < 700
					? null
					: <Button
						text={t("home.header.upload_btn")}
						styleType={"outlined"}
						link
						linkUrl={"/dashboard/posts/upload"}
						id={"header_full_screen_upload_item"}
					/>
				}

				{/*Responsive menu*/}
				<div className={styles.home_header_responsive_menu} id={"header_responsive_menu"}>
					<img
						src={moreIcon.src}
						className={styles.home_header_responsive_menu_icon}
						alt="more"
						onClick={() => setShowResponsiveMenu(!showResponsiveMenu)}
					/>

					{
						showResponsiveMenu
							? <div
								className={styles.home_header_responsive_menu_box}
								ref={wrapperRef}
							>
								<Link href="/#how">
									<p className={styles.home_header_responsive_link}>
										{t("home.header.how_works")}
									</p>
								</Link>
								<Link href="/category">
									<p
										className={styles.home_header_responsive_link}
										id={"header_category_link_full_screen_responsive"}
									>
										{t("home.header.categories")}
									</p>
								</Link>
								<Link href="/posts">
									<p
										className={styles.home_header_responsive_link}
										id={"header_search_article_link_full_screen_responsive"}
									>
										{t("home.header.search")}
									</p>
								</Link>
								<Link href="/info/companies">
									<p
										className={styles.home_header_responsive_link}
										id={"header_for_companies_link_full_screen_responsive"}
									>
										{t("home.header.companies")}
									</p>
								</Link>
							</div>
							: null
					}

				</div>

			</div>
		</header>
	)
}
