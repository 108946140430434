import styles from "./Footer.module.css";
import logo from "../../../img/branding/logo_white.png";
import {useTranslation} from "next-i18next";
import Link from "next/link";
import {motion} from "framer-motion";
import { SocialIcon } from 'react-social-icons';
import {appStoreLink, facebookLink, instagramLink, linkedinLink, playMarketLink} from "../../../constants";
import packageJson from "../../../package.json";
import appStoreButtonImage from "../../../img/components/Footer/appStore.svg";
import playMarketButtonImage from "../../../img/components/Footer/googlePlay.svg";
import moment from "moment";

export default function Footer() {
	const { t } = useTranslation();

	return(
		<footer
			className={styles.footer_container}
			data-testid="cy_footer"
		>

			<div className={styles.footer}>
				<div className={styles.footer_branding}>
					<Link href="/">
						<motion.img
							src={logo.src}
							alt="shary_logo_footer"
						/>
					</Link>

					<p
						className={styles.version}
						data-testid="cy_footer_version"
					>
						{`@${packageJson.version}`}
					</p>

					<div className={styles.storeButtons}>

						<Link
							href={appStoreLink}
							data-testid="cy_footer_app_store_link"
						>
							<img
								className={styles.storeButton}
								src={appStoreButtonImage.src}
								alt="shary_app_store"
							/>
						</Link>

						<Link
							href={playMarketLink}
							data-testid="cy_footer_play_market_link"
						>
							<img
								className={styles.storeButton}
								src={playMarketButtonImage.src}
								alt="shary_play_market"
							/>
						</Link>

					</div>

				</div>

				<div className={styles.footer_content}>
					<h6>
						{t("home.home.footer.block_1.title")}
						<span>.</span>
					</h6>
					<Link
						data-testid="cy_footer_link_lender"
						href={"/info/lender"}
					>
						{t("home.home.footer.block_1.link_1")}
					</Link>
					<Link
						href={"/info/renter"}
						data-testid="cy_footer_link_renter"
					>
						{t("home.home.footer.block_1.link_2")}
					</Link>
					<Link
						href={"/info/companies"}
						data-testid="cy_footer_link_companies"
					>
						{t("home.home.footer.block_1.link_3")}
					</Link>
					<Link
						href={"/info/faq"}
						data-testid="cy_footer_link_faq"
					>
						{t("home.home.footer.block_1.link_4")}
					</Link>
					<Link
						href={"/info/insurance"}
						data-testid="cy_footer_link_insurance"
					>
						{t("home.home.footer.block_1.link_5")}
					</Link>
				</div>

				<div className={styles.footer_content}>
					<h6>
						{t("home.home.footer.block_2.title")}
						<span>.</span>
					</h6>
					<Link
						href={"/info/contact"}
						data-testid="cy_footer_link_contact"
					>
						{t("home.home.footer.block_2.link_1")}
					</Link>
					<Link
						href={"/info/about"}
						data-testid="cy_footer_link_about"
					>
						{t("home.home.footer.block_2.link_2")}
					</Link>
					<Link
						href={"/info/press"}
						data-testid="cy_footer_link_press"
					>
						{t("home.home.footer.block_2.link_3")}
					</Link>
				</div>

				<div className={styles.footer_content}>
					<h6>
						{t("home.home.footer.block_3.title")}
						<span>.</span>
					</h6>
					<Link
						href={"/info/circular"}
						data-testid="cy_footer_link_circular"
					>
						{t("home.home.footer.block_3.link_1")}
					</Link>
					<Link
						href={"/search"}
						data-testid="cy_footer_link_circular"
					>
						{t("home.home.footer.block_3.link_2")}
					</Link>
				</div>

				<div className={styles.footer_content}>
					<h6>
						{t("home.home.footer.block_4.title")}
						<span>.</span>
					</h6>
					<div className="row">
						<SocialIcon
							data-testid="cy_footer_link_social_facebook"
							className={styles.social}
							url={facebookLink}
						/>
						<SocialIcon
							data-testid="cy_footer_link_social_instagram"
							className={styles.social}
							url={instagramLink}
						/>
						<SocialIcon
							data-testid="cy_footer_link_social_linkedin"
							className={styles.social}
							url={linkedinLink}
						/>
					</div>
				</div>

			</div>

			<div className={styles.footer_footer}>

				<p>
					{t("home.home.footer.footer.copyright", {year: moment().format("YYYY")})}
				</p>

				<div className="row">
					<Link
						href={"/info/terms"}
						data-testid="cy_footer_link_terms"
					>
						{t("home.home.footer.footer.terms")}
					</Link>
					<Link
						href={"/info/agb"}
						data-testid="cy_footer_link_agb"
					>
						{t("home.home.footer.footer.agb")}
					</Link>
					<Link
						href={"/info/policy"}
						data-testid="cy_footer_link_policy"
					>
						{t("home.home.footer.footer.policy")}
					</Link>
					<Link
						href={"/info/imprint"}
						data-testid="cy_footer_link_imprint"
					>
						{t("home.home.footer.footer.impressum")}
					</Link>
				</div>

			</div>

		</footer>
	)
}
