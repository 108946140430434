import {useEffect, useState} from "react";
import styles from "./MobileBottomTab.module.css";
import homeIcon from "../../../img/home/navigation_icons/home_icon.png";
import homeIconSelected from "../../../img/home/navigation_icons/home_icon_selected.png";
import userIcon from "../../../img/home/navigation_icons/user_icon.png";
import userIconSelected from "../../../img/home/navigation_icons/user_icon_selected.png";
import {useTranslation} from "next-i18next";
import Link from "next/link";
import {motion} from "framer-motion";
import {useRouter} from "next/router";
import {useAppSelector} from "../../../redux/store";

export default function MobileBottomTab() {
	const { t } = useTranslation()
	const router = useRouter()
	const user = useAppSelector((state) => state.user.user);

	const [selectedPage, setSelectedPage] = useState("");
	const [userLoggedIn, setUserLoggedIn] = useState(false);

	useEffect(() => {
		if (user?.email) {
			setUserLoggedIn(true);
		} else {
			setUserLoggedIn(false)
		}
	}, [user])

	// Detect path and set selected default tab
	useEffect(() => {
		if (router?.pathname?.split("/").length > 2) {
			setSelectedPage(router?.pathname?.split("/")[2]);
		} else {
			setSelectedPage("/");
		}
	}, [router])

	const routes = [
		{
			key: "1",
			path: "/",
			icon: homeIcon,
			selectedIcon: homeIconSelected,
			name: t("components.mobile_bottom_tab.home")
		},
		{
			key: "2",
			path: "/auth/signin",
			icon: userIcon,
			selectedIcon: userIconSelected,
			name: userLoggedIn
				? t("components.mobile_bottom_tab.profile")
				: t("components.mobile_bottom_tab.sign_in")
		}
	]

	return <footer className={styles.mobile_bottom_tab}>
		<div className="row">
			{routes.map((route:any) => (
				<Link
					key={route.key}
					href={route.path}
				>
					<div
						className={styles.mobile_bottom_tab_item}
					>
						<motion.img
							src={route.path === selectedPage ? route.selectedIcon.src : route.icon.src}
							alt="home"
						/>
						<p className={route.path === selectedPage ? styles.selected : ""}>
							{route.name}
						</p>
					</div>
				</Link>
			))}
		</div>
	</footer>
}
