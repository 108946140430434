import Header from "../../shared/Header/Header";
import styles from "./HomeLayout.module.css"
import {mobileScreenWidth} from "../../../constants";
import {useWindowSize} from "../../../hooks/useWindowSize";
import {ReactNode} from "react";
import Footer from "../../shared/Footer/Footer";
import MobileBottomTab from "../../shared/MobileBottomTab/MobileBottomTab";
import { useRouter } from 'next/router'

interface IProps {
	children: ReactNode;
	hideMobileFooter?: boolean;
}

export default function HomeLayout(props:IProps) {
	const { width } = useWindowSize()
	const router = useRouter()

	const disabledFooterPath = ["posts"]

	return(
		<main>
			{width && width > mobileScreenWidth
				? <Header />
				: null
			}

			<div
				className={styles.home_layout}
				data-testid="cy_home_layout_container"
			>
				{props.children}
				{disabledFooterPath.includes(router.pathname.split("/")[1])
					? null
					: width && width > mobileScreenWidth
						? <Footer />
						: <div>
							{!props.hideMobileFooter && <Footer />}
							<MobileBottomTab />
						</div>
				}
			</div>

		</main>
	)
}
